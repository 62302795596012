import React, { useEffect, useMemo, useState } from "react";
import qs from "qs";
import { RouteComponentProps, withRouter } from "react-router";
import { connect, useSelector } from "react-redux";

import * as CAStyles from "components/clientAdmin/styles";
import * as S from "./styles";
import BackToTop from "components/common/TableUI/BackToTop";
import Breadcrumbs from "components/common/Breadcrumbs";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import DefaultCell from "components/common/TableUI/DefaultCell";
import ExpandableTableCell from "components/common/TableUI/ExpandableTableCell";
import Loader from "components/common/Loader";
import Modal from "components/common/Modal";
import Pagination, {
  PaginationWrapper,
} from "components/common/TableUI/Pagination";
import Popper from "components/common/Popper";
import RowsPerPage from "components/common/TableUI/RowsPerPage";
import { StatsCard } from "components/common/StatsCard";
import TableSummary from "components/common/TableUI/TableSummary";
import UsersSearchBar from "components/filters/UsersSearchBar";
import moment from "moment";
import withClientGroups from "../dataHelpers/withClientGroups";
import { AppState } from "store";
import { ClientGroupsResponse } from "store/clientGroups/types";
import { FilterSelect } from "components/common/FilterSelect";
import { History } from "history";
import { MoreVertIcon } from "components/common/Card/styles";
import { PageContent } from "components/clientAdmin/styles";
import { ParticipantsAggregateSummary } from "store/participantsAggregateSummary/types";
import {
  ParticipantsSummaryData,
  ParticipantSummary,
} from "store/participantsSummary/types";
import { ResendVerificationEmailConfirmation } from "./modalContent/ResendVerificationEmailConfirmation";
import { SubmitButton } from "components/clientAdmin/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "components/common/styled/Table";
import { UserActivationStatusConfirmation } from "./modalContent/UserActivationStatusConfirmation";
import {
  getSubOrdinatesOptions,
  timeFilterOptions,
  TimeFilterType,
} from "components/helpers/filters";
import { getExportUrl, generateGroupOptions } from "../helpers";
import { getParticipantsAggregateSummary } from "store/participantsAggregateSummary/actions";
import {
  getParticipantsSummary,
  resetParticipantsSummary,
} from "store/participantsSummary/actions";
import {
  getActivationStatusButtonLabel,
  getActivationStatusForPayload,
  getActivationStatusForTable,
  getEnabledOrDisabledWording,
  requestUsers,
  showDisableEnableItem,
  showEmailValidationItem,
} from "./helpers";
import {
  resendValidationEmail,
  changeActivationStatus,
} from "store/usersParticipants/actions";
import { SortDirection } from "store/common/types";
import { DateRangeValue } from "components/common/DateRange";
import ReadOnlyContent from "components/common/permissions/ReadOnlyContent";
import Link from "components/common/Link";
import { usersFiltersSelector } from "store/filters/users/selectors";
import {
  setUsersFilters,
  setUsersPagination,
} from "store/filters/users/actions";
import { participantTreeOptionsSelector } from "store/participantTreeFilter/selectors";

interface UsersProps extends RouteComponentProps {
  currentPage?: number;
  dispatch: any;
  groups?: ClientGroupsResponse;
  history: History;
  size?: number;
  stats?: ParticipantsAggregateSummary;
  statsLoading: boolean;
  totalElements?: number;
  totalPages?: number;
  users?: ParticipantsSummaryData;
  usersLoading?: boolean;
}

const mockStatusOptions = [
  {
    id: -1,
    value: "All Statuses",
  },
  {
    id: 1,
    value: "Active",
  },
  {
    id: 2,
    value: "Disabled",
  },
  {
    id: 3,
    value: "Not registered",
  },
  {
    id: 4,
    value: "N/A",
  },
];

interface Sorting {
  [key: string]: {
    direction: SortDirection;
    type?: string;
  };
}

const Users = (props: UsersProps) => {
  const {
    dispatch,
    groups,
    history,
    location,
    stats,
    totalElements,
    totalPages,
    users,
    usersLoading,
  } = props;

  const filtersSelect = useSelector(usersFiltersSelector);
  const participantTreeOptions = useSelector(participantTreeOptionsSelector);
  const subordinatesOptions = getSubOrdinatesOptions(participantTreeOptions);
  const { filterParams, paginationParams } = filtersSelect;

  // Pagination constants
  const [size, setSize] = useState(paginationParams.size || 10);
  const [currentPage, setCurrentPage] = useState(paginationParams.page || 0);

  // Get default filters from url
  const params = qs.parse(location.search.replace("?", "")) || {};
  const defaultGroupId = params.group || filterParams.groupId;

  // Filters
  const [dateRange, setDateRange] = useState<DateRangeValue | null>(
    filterParams.dateRange as any
  );
  const [group, setGroup] = useState(defaultGroupId);
  const [query, setQuery] = useState(filterParams.query);
  // const [role, setRole] = useState(-1);
  const [searchQuery, setSearchQuery] = useState(filterParams.query);
  const [status, setStatus] = useState(filterParams.status);
  const [timeFilterType, setTimeFilterType] = useState<TimeFilterType>(
    filterParams.timeFilterType as TimeFilterType
  );
  const [subordinatesFilter, setSubordinatesFilter] = useState<string>(
    filterParams.subordinatesFilter
  );

  const initialCustomDates = filterParams.dateRange
    ? {
        // @ts-ignore
        from: moment(filterParams.dateRange?.startDate),
        // @ts-ignore
        to: moment(filterParams.dateRange?.endDate),
      }
    : {};

  const setGroupIdValue = (value: number) => {
    setGroup(value);
    dispatch(setUsersFilters({ ...filterParams, groupId: value }));
  };
  const setStatusValue = (value: number) => {
    setStatus(value);
    dispatch(setUsersFilters({ ...filterParams, status: value }));
  };
  const setTimeFilterTypeValue = (value: TimeFilterType) => {
    setTimeFilterType(value);
    dispatch(setUsersFilters({ ...filterParams, timeFilterType: value }));
  };
  const setSubordinatesFilterValue = (value: TimeFilterType) => {
    setSubordinatesFilter(value);
    dispatch(setUsersFilters({ ...filterParams, subordinatesFilter: value }));
  };
  const setDateRangeValue = (value: DateRangeValue | null) => {
    setDateRange(value);
    dispatch(setUsersFilters({ ...filterParams, dateRange: value as any }));
  };
  const setSearchQueryValue = (value: string) => {
    setSearchQuery(value);
    dispatch(setUsersFilters({ ...filterParams, query: value }));
  };

  const sortQuery = (paginationParams.sort as string) || "firstName,asc";
  const sorting = sortQuery.split(",");
  const sortBy = (type, direction) => {
    dispatch(
      setUsersPagination({ ...paginationParams, sort: `${type},${direction}` })
    );
  };

  // Popper context menu
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [anchorUser, setAnchorUser] = useState<ParticipantSummary>();
  const [popperIsOpen, setPopperIsOpen] = useState(false);

  // USER ACTIVATION STATUS
  const [activationStatusError, setActivationStatusError] = useState(false);
  const [activationStatusIsLoading, setActivationStatusIsLoading] = useState(
    false
  );
  const [
    userActivationConfirmationModalIsVisible,
    setUserActivationConfirmationModalIsVisible,
  ] = useState(false);
  const [
    userActivationOutcomeModalIsVisible,
    setUserActivationOutcomeModalIsVisible,
  ] = useState(false);
  const enabledOrDisabled = useMemo(
    () => getEnabledOrDisabledWording(anchorUser && anchorUser.status),
    [anchorUser && anchorUser.status]
  );

  // Resend verification email
  const [validationEmailError, setValidationEmailError] = useState(false); // API call resulted in error
  const [validationEmailIsLoading, setValidationEmailIsLoading] = useState(
    false
  ); // API call is pending
  // First modal (confirmation)
  const [
    validationEmailConfirmationModalIsVisible,
    setValidationEmailConfirmationModalIsVisible,
  ] = useState(false);
  // Second modal (outcome)
  const [
    validationEmailOutcomeModalIsVisible,
    setValidationEmailOutcomeModalIsVisible,
  ] = useState(false);

  // On filter change, get users/aggregate stats and reset `currentPage`
  useEffect(() => {
    if (timeFilterType === "CUSTOM_RANGE" && !dateRange) {
      // Prevent the table reloading until the range is set.
      return;
    }
    requestUsers({
      dateRange,
      dispatch,
      getParticipantsAggregateSummary,
      getParticipantsSummary,
      group,
      page: 0,
      participantTreeFilter: subordinatesFilter,
      searchQuery,
      size,
      sortQuery,
      status,
      timeFilterType,
    });
    setCurrentPage(0);
  }, [
    dateRange,
    dispatch,
    group,
    searchQuery,
    size,
    status,
    timeFilterType,
    subordinatesFilter,
  ]);

  // On non-filter change, get documents/aggregate stats but don't reset `currentPage`
  useEffect(() => {
    requestUsers({
      dateRange,
      dispatch,
      getParticipantsAggregateSummary,
      getParticipantsSummary,
      group,
      noStats: true,
      page: currentPage,
      searchQuery,
      size,
      sortQuery,
      status,
      timeFilterType,
    });
  }, [dispatch, currentPage, paginationParams.sort]);

  // Values for stat cards above the table
  const statsMap = useMemo(() => {
    const activeUsersNotAvailable = ![-1, 1].includes(status);
    return [
      {
        key: "numberOfAllUsers",
        name: "Number of Users",
      },
      {
        key: "numberOfGroups",
        linkTo: "/people/groups",
        name: "Number of Groups",
      },
      {
        key: "numberOfActiveUsers",
        name: "Number of Active Users",
        notAvailable: activeUsersNotAvailable,
      },
    ];
  }, [status]);

  // On unmount
  useEffect(() => {
    return () => {
      dispatch(resetParticipantsSummary);
    };
  }, [dispatch]);

  return (
    <>
      {/* Amazing modal for the outcome when changing a user's activation status */}
      <Modal
        border="none"
        open={userActivationOutcomeModalIsVisible}
        handleClose={() => {
          setActivationStatusError(false);
          setUserActivationOutcomeModalIsVisible(false);
        }}
        height="auto"
        width="652px"
        padding="24px 23px"
        content={
          <S.ModalPromptWrapper>
            <S.ModalPromptTitle>
              {activationStatusError
                ? `Error - Unable to ${
                    enabledOrDisabled === "disable" ? "enable" : "disable"
                  } user`
                : `User has been ${
                    enabledOrDisabled === "disable" ? "enabled" : "disabled"
                  }`}
            </S.ModalPromptTitle>
            <S.ModalPromptSubtitle>
              {activationStatusError
                ? `The system encountered an error while trying to ${
                    enabledOrDisabled === "disable" ? "enable" : "disable"
                  } the selected user. Please try again.`
                : `The selected user’s account has been successfully ${
                    enabledOrDisabled === "disable" ? "enabled" : "disabled"
                  }. User will ${(enabledOrDisabled == "enable" &&
                    "no longer") ||
                    "now"} be able to log in.`}
            </S.ModalPromptSubtitle>
            <S.DisableUserButtonsWrapper>
              <SubmitButton
                onClick={() => {
                  setActivationStatusError(false);
                  setUserActivationOutcomeModalIsVisible(false);
                }}
              >
                <span>close </span>
              </SubmitButton>
            </S.DisableUserButtonsWrapper>
            <S.ModalPromptCloseIcon
              onClick={() => {
                setActivationStatusError(false);
                setUserActivationOutcomeModalIsVisible(false);
              }}
            />
          </S.ModalPromptWrapper>
        }
      />
      {/* // Amazing modal for changing a user's activation status */}
      <Modal
        border="none"
        open={userActivationConfirmationModalIsVisible}
        handleClose={() => {
          setUserActivationConfirmationModalIsVisible(false);
        }}
        height="auto"
        width="652px"
        padding="24px 23px"
        content={
          <UserActivationStatusConfirmation
            // user data
            email={anchorUser && anchorUser.email}
            firstName={anchorUser && anchorUser.firstName}
            groupNames={anchorUser && anchorUser.groupNames}
            status={anchorUser && anchorUser.status}
            lastLogin={anchorUser && anchorUser.lastLogin}
            lastName={anchorUser && anchorUser.lastName}
            // rest
            enabledOrDisabled={enabledOrDisabled}
            loading={activationStatusIsLoading}
            onCancel={() => setUserActivationConfirmationModalIsVisible(false)}
            onSubmit={() => {
              let payload = {};
              if (anchorUser) {
                payload = {
                  isActive: getActivationStatusForPayload(anchorUser.status), // Function sets it to opposite
                  participantId: anchorUser.id,
                  userId: anchorUser.userId,
                };
              }
              setActivationStatusIsLoading(true);
              dispatch(changeActivationStatus(payload)).then((e) => {
                setActivationStatusIsLoading(false);
                setUserActivationConfirmationModalIsVisible(false);
                setUserActivationOutcomeModalIsVisible(true);
                if (e.type === "CHANGE_ACTIVATION_STATUS_SUCCESS") {
                  setActivationStatusError(false); // just a backup, probably not necessary
                } else {
                  setActivationStatusError(true);
                }
              });
            }}
          />
        }
      />
      {/* Modal for the outcome when resending verification email */}
      <Modal
        border="none"
        open={validationEmailOutcomeModalIsVisible}
        handleClose={() => {
          setValidationEmailError(false);
          setValidationEmailOutcomeModalIsVisible(false);
        }}
        height="auto"
        width="652px"
        padding="24px 23px"
        content={
          <S.ModalPromptWrapper>
            <S.ModalPromptTitle>
              {validationEmailError
                ? "Error - Validation Email NOT sent"
                : "Validation Email has sent"}
            </S.ModalPromptTitle>
            <S.ModalPromptSubtitle>
              {validationEmailError
                ? `The system encountered an error while trying to send a Validation email to 
                the selected user. Please try again.`
                : "A Validation email has been sent to the selected user."}
            </S.ModalPromptSubtitle>
            <S.DisableUserButtonsWrapper>
              <SubmitButton
                onClick={() => {
                  setValidationEmailError(false);
                  setValidationEmailOutcomeModalIsVisible(false);
                }}
              >
                <span>close </span>
              </SubmitButton>
            </S.DisableUserButtonsWrapper>
            <S.ModalPromptCloseIcon
              onClick={() => {
                setValidationEmailError(false);
                setValidationEmailOutcomeModalIsVisible(false);
              }}
            />
          </S.ModalPromptWrapper>
        }
      />
      {/* // Modal for resending verification email */}
      <Modal
        border="none"
        open={validationEmailConfirmationModalIsVisible}
        handleClose={() => {
          setValidationEmailConfirmationModalIsVisible(false);
        }}
        height="auto"
        width="652px"
        padding="24px 23px"
        content={
          <ResendVerificationEmailConfirmation
            // user data
            email={anchorUser && anchorUser.email}
            firstName={anchorUser && anchorUser.firstName}
            groupNames={anchorUser && anchorUser.groupNames}
            // status={anchorUser && anchorUser.status}
            lastLogin={anchorUser && anchorUser.lastLogin}
            lastName={anchorUser && anchorUser.lastName}
            // rest
            loading={validationEmailIsLoading}
            onCancel={() => setValidationEmailConfirmationModalIsVisible(false)}
            onSubmit={() => {
              const _anchorUser = anchorUser;
              let payload;
              if (_anchorUser) {
                payload = {
                  email: _anchorUser.email,
                  status: _anchorUser.status,
                };
                setValidationEmailIsLoading(true);
                dispatch(resendValidationEmail(payload)).then((e) => {
                  setValidationEmailIsLoading(false);
                  setValidationEmailConfirmationModalIsVisible(false);
                  setValidationEmailOutcomeModalIsVisible(true);
                  if (e.type === "RESEND_VALIDATION_EMAIL_SUCCESS") {
                    setValidationEmailError(false);
                  } else {
                    setValidationEmailError(true);
                  }
                });
              }
            }}
          />
        }
      />
      <Popper
        anchorEl={anchorEl}
        onClose={() => {
          // setPopperIsOpen(false);
        }}
        open={popperIsOpen}
        placement="left"
      >
        <S.PopperWrapper>
          <S.PopperRow
            onClick={() =>
              history.push(`/people/users/view/${anchorUser && anchorUser.id}`)
            }
          >
            <ReadOnlyContent component={<span>View user profile</span>}>
              <span>View/edit user profile</span>
            </ReadOnlyContent>
            <S.PopperChevron />
          </S.PopperRow>
          {/* Resend validation/activation email menu item  */}
          {showEmailValidationItem(anchorUser?.status) && (
            <ReadOnlyContent>
              <S.PopperRow
                onClick={() => {
                  setValidationEmailConfirmationModalIsVisible(true);
                }}
              >
                <div className="d-flex flex-row align-items-center">
                  <div className="mr-3">
                    {anchorUser?.status === "DISABLED"
                      ? "Resend validation email"
                      : "Send activation email"}
                  </div>
                </div>
                <S.PopperChevron />
              </S.PopperRow>
            </ReadOnlyContent>
          )}
          {/* Disable/enable user menu item  */}
          {showDisableEnableItem(anchorUser && anchorUser.status) && (
            <ReadOnlyContent>
              <S.PopperRow
                error={true}
                onClick={() => {
                  setUserActivationConfirmationModalIsVisible(true);
                }}
              >
                <span>
                  {getActivationStatusButtonLabel(
                    anchorUser && anchorUser.status
                  )}
                </span>
                <S.PopperChevron />
              </S.PopperRow>
            </ReadOnlyContent>
          )}
        </S.PopperWrapper>
      </Popper>
      <PageContent>
        {/* 
          @NOTE: ID IS USED FOR "SCROLL TO" / "BACK TO TOP" LOGIC
          @TODO: Update with a better solution - Trevor
        */}
        <div id="mainContent" className="col-12">
          <Breadcrumbs
            paths={[
              {
                pathName: "People",
              },
              {
                pathName: "Users",
                href: "/people/users",
              },
            ]}
          />
        </div>

        <CAStyles.TableWrapper>
          <S.PageHeader>
            <S.PageTitle>Users</S.PageTitle>
            <ReadOnlyContent>
              <S.PageActionButton
                onClick={() => history.push("/people/users/add-user")}
              >
                Add user
              </S.PageActionButton>
            </ReadOnlyContent>
          </S.PageHeader>

          {/* Filters */}
          <div className="col-12 d-flex flex-row justify-content-start align-items-center">
            <div className="mr-2">
              <FilterSelect
                name="groups"
                value={group}
                label=""
                options={generateGroupOptions((groups && groups.content) || [])}
                handleChange={(e) => setGroupIdValue(e.target.value)}
              />
            </div>
            {/* <div className="ml-2 mr-2">
              <FilterSelect
                name="roles"
                value={role}
                label=""
                options={mockRoleOptions}
                handleChange={(e) => setRole(e.target.value)}
              />
            </div> */}
            <div className="ml-2 mr-2">
              <FilterSelect
                name="statuses"
                value={status}
                label=""
                options={mockStatusOptions}
                handleChange={(e) => setStatusValue(e.target.value)}
              />
            </div>
            <div className="ml-2 mr-2">
              <FilterSelect
                name="timeFilter"
                value={timeFilterType}
                label=""
                options={timeFilterOptions}
                handleChange={(e) => setTimeFilterTypeValue(e.target.value)}
              />
            </div>
            {!participantTreeOptions.hideFilter && (
              <div className="ml-2 mr-2">
                <FilterSelect
                  name="subordinatesFilter"
                  value={subordinatesFilter}
                  label=""
                  options={subordinatesOptions}
                  handleChange={(e) =>
                    setSubordinatesFilterValue(e.target.value)
                  }
                />
              </div>
            )}
            <div style={{ width: "398px" }}>
              <UsersSearchBar
                onChange={setQuery}
                onSearch={setSearchQueryValue}
                query={query}
              />
            </div>
          </div>

          {timeFilterType === "CUSTOM_RANGE" && (
            <S.DateRangeRow>
              <span className="visually-hidden" id="custom-range-label">
                Custom Range
              </span>
              <S.DateRange
                handleChange={setDateRangeValue}
                labelId="custom-range-label"
                name="customRange"
                onClear={() => {
                  setDateRangeValue(null);
                  setTimeFilterTypeValue("ALL_TIME");
                }}
                initialValues={initialCustomDates}
              />
            </S.DateRangeRow>
          )}

          {/* Stats */}
          <Loader loading={usersLoading}>
            <CAStyles.StatsWrapper>
              {statsMap.map((e, i) => (
                <StatsCard
                  header={e.name}
                  history={(e.linkTo && history) || undefined}
                  key={i}
                  linkTo={e.linkTo}
                  notAvailable={e.notAvailable}
                  stretch
                  value={stats && stats[e.key]}
                  valueName=""
                />
              ))}
            </CAStyles.StatsWrapper>
          </Loader>

          <div className="col-12 mt-3 mb-3">
            <Loader loading={usersLoading}>
              {users && (
                <>
                  <TableSummary
                    pageSize={size}
                    currentPage={currentPage}
                    totalElements={totalElements}
                    ofWhat="users"
                    exports={["print", "xls", "csv"]}
                    exportUrl={getExportUrl(
                      "participants/summary",
                      query,
                      `sort=${sortQuery}`
                    )}
                  />
                  <Table>
                    <TableHead>
                      <TableRow noBottomBorder>
                        <TableCell
                          active={sorting[0] === "firstName"}
                          onClick={(dir) => sortBy("firstName", dir)}
                          sortDirection={
                            sorting[0] === "firstName"
                              ? (sorting[1] as SortDirection)
                              : "asc"
                          }
                        >
                          User Name
                        </TableCell>
                        {/* <TableCell>Role</TableCell> */}
                        <TableCell width="200px">Group</TableCell>
                        <TableCell
                          active={sorting[0] === "lastLogin"}
                          onClick={(dir) => sortBy("lastLogin", dir)}
                          sortDirection={
                            sorting[0] === "lastLogin"
                              ? (sorting[1] as SortDirection)
                              : "asc"
                          }
                          width="150px"
                        >
                          Last Login
                        </TableCell>
                        <TableCell
                          active={sorting[0] === "status"}
                          onClick={(dir) => sortBy("status", dir)}
                          sortDirection={
                            sorting[0] === "status"
                              ? (sorting[1] as SortDirection)
                              : "asc"
                          }
                          width="160px"
                        >
                          Status
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {users.content &&
                        users.content.map((user, i) => (
                          <TableRow key={i}>
                            <TableCell>
                              <Link to={`/people/users/view/${user.id}`}>
                                {user.firstName} {user.lastName}
                              </Link>
                              <S.EmailText>{user.email || "-"}</S.EmailText>
                            </TableCell>
                            {/* <TableCell>
                              <DefaultCell value={prettifyRole(user.roleName)} />
                            </TableCell> */}
                            <ExpandableTableCell items={user.groupNames} />
                            <TableCell>
                              <DefaultCell
                                value={
                                  (user.lastLogin &&
                                    moment(user.lastLogin).format(
                                      "MM/DD/YYYY"
                                    )) ||
                                  "-"
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <ClickAwayListener
                                onClickAway={() => {
                                  if (popperIsOpen) {
                                    setPopperIsOpen(false);
                                  }
                                }}
                              >
                                <div className="d-flex flex-row justify-content-between">
                                  <DefaultCell
                                    value={getActivationStatusForTable(
                                      user.status
                                    )}
                                  />
                                  {user.id && (
                                    <MoreVertIcon
                                      onClick={(e) => {
                                        setAnchorEl(e.currentTarget);
                                        // setPopperIsOpen(true);
                                        setAnchorUser(user);
                                        setActivationStatusError(false);
                                        setTimeout(() => {
                                          setPopperIsOpen(true);
                                        }, 100);
                                      }}
                                    />
                                  )}
                                </div>
                              </ClickAwayListener>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>

                  {/* // Pagination */}
                  {users && (
                    <PaginationWrapper>
                      <RowsPerPage
                        pageSize={size}
                        onClick={(size) => {
                          setSize(size);
                        }}
                      />
                      <Pagination
                        currentPage={currentPage}
                        onClick={(page) => setCurrentPage(page)}
                        totalPages={totalPages}
                      />
                      <BackToTop />
                    </PaginationWrapper>
                  )}
                </>
              )}
            </Loader>
          </div>
        </CAStyles.TableWrapper>
      </PageContent>
    </>
  );
};

const mapStateToProps = (state: AppState) => {
  let totalPages, totalElements;
  if (state.participantsSummary.data) {
    totalElements = state.participantsSummary.data.totalElements;
    totalPages = state.participantsSummary.data.totalPages;
  }
  return {
    groups: state.clientGroups.data.clientGroups,
    stats: state.participantsAggregateSummary.data,
    statsLoading: state.participantsAggregateSummary.loading,
    totalElements: totalElements,
    totalPages: totalPages,
    users: state.participantsSummary.data,
    usersLoading: state.participantsSummary.loading,
  };
};

export default withClientGroups(withRouter(connect(mapStateToProps)(Users)));
